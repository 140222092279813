import React  from 'react'

const Pause =  ({ width, height}) => (
    <svg fill="currentColor" height={height || 60} viewBox="0 0 24 24" width={width || 60} xmlns="http://www.w3.org/2000/svg">
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
        <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
)

export default Pause
