import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { Transition } from "react-transition-group"

import Player from '../components/Player'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { TranslateOpacityAnimation } from '../styles/animations'

const Row = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-left: -1.0875rem;
    margin-right: -1.0875rem;
`

const Column = styled.div`
    padding-left: 1.0875rem;
    padding-right: 1.0875rem;
    flex: 1 1 100%;
    
    @media(min-width: 768px) {
        flex: 1 1 0%;
    }
`

const ColumnOdd = styled(Column)`

    text-align: right;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    
    @media(min-width: 768px) {
        padding-right: 2.175rem;
        padding-left: 2.175rem;
        padding-bottom: 8rem;
    }
`

const ColumnEven = styled(Column)`

    padding-top: 4rem;
    padding-bottom: 2.5rem;
    
    @media(min-width: 768px) {
        padding-left: 2.175rem;
        padding-right: 2.175rem;
        padding-top: 5rem;
    }
`

const Headings2 = styled.h2`
    font-size: 1.625rem;
    line-height: 1.3;
    text-transform: uppercase;
    display: block;
    margin: 0;
`

const ButtonWrapper= styled.div`
    text-align: center;
`

const Button = styled.a`
    display: inline-block;
    border: 1px solid #FFF;
    padding: .575rem 1.5rem;
    margin: .875rem 0 0;
    text-decoration: none;
    font-family: "Heebo", "Open Sans", Roboto, "Helevetica Neue", sans-serif;
    font-size: .875rem;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out,
        color 250ms ease-in-out;
    appearance: none;
    
    &:hover,
    &:focus {
        background: #FFF;
        color: #000;
    }
`

const ButtonBlack = styled(Button)`
    background: #000;
    color: #FFF;
    
    &:hover,
    &:focus {
        background: #FFF;
        color: #000;
    }
`

const Expertext = styled.div`
    padding-left: 8vw;
    padding-right: 8vw;
    max-width: 500px;
    font-weight: 300;
    line-height: 1.8;
    transform: translateY(-1.25rem);
    color: rgba(255, 255, 255, 0.7);
   
    @media(min-width: 768px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    
    p {
        &:before {
            content: ' ';
            display: block;
            position: relative;
            float: left;
            height: ${Math.floor(18 * 1.8 - 1)}px;
            width: 5rem;
        }
    }
`

export const Animation = styled.div`
  transition:.6s;
  /* example for move item */
  transform: translate(
    ${({ state, translateX}) => (state === "entered" ? 0 : translateX)}, ${({ state }) => (state === "entered" ? 0 : 20)}px
  );
  opacity: ${({ state }) => (state === "enteringzz" || state === "entered" ? 1 : 0)}
`

const IndexPage = ({data}) => (
  <Layout>
    <SEO title={data.wordpressPage.title} />
    <Row>
    {/*<div css={`    text-align: center;
    font-size: 500px;
    font-weight: 700;
    display: block;
    width: 100%;
    letter-spacing: -0.1em;`}>
<span>TR</span>
</div>*/}
        <ColumnOdd>
            {(<>
                <Headings2>Musique <br/>Music&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Headings2>
                <Player client_id="69969fa7cfd047fac325bba9ddb0adba" audio_secret_token="s-5oX1V" audio_id="213972703" title="" />
                <ButtonBlack href="https://soundcloud.com/thibaultrivrain/" target="_blank" rel="noopener" style={{marginRight: `6rem`}}>
                    { `Soundcloud` }
                </ButtonBlack>
            </>).props.children.map((child, i) => (
                <Transition in={true} appear={true} timeout={400 + i * 1.5 * 100} key={i}>
                    {(state) => (
                        <TranslateOpacityAnimation state={state} translateX={`-10px`}>{child}</TranslateOpacityAnimation>
                    )}
                </Transition>
            ))}
        </ColumnOdd>
        <ColumnEven>
            {(<>
                <Headings2>DÉVELOPPEMENT <br/>WEB FULL <br/>STACK</Headings2>
                <Expertext>
                    <p>WordPress, React, Gastby, HTML, Vanilla JS, CSS et SCSS</p>
                </Expertext>
                <ButtonWrapper>
                    <ButtonBlack href="mailto:thibaultrivrain@gmail.com" target="_blank" rel="noopener">
                        { `Contact` }
                    </ButtonBlack>
                </ButtonWrapper>
            </>).props.children.map((child, i) => (
                <Transition in={true} appear={true} timeout={700 + i * 100} key={i}>
                    {(state) => (
                        <TranslateOpacityAnimation state={state} translateX={`10px`}>{child}</TranslateOpacityAnimation>
                    )}
                </Transition>
            ))}
        </ColumnEven>
    </Row>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    wordpressPage(pageOnFront: {eq: true}) {
        wordpress_id
        id
        title
      }
  }
`
